export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore()

  if (!userStore.isLoggedIn && to.name) {
    return navigateTo({
      name: 'login',
      query: { redirect: to.name.toString() },
    })
  }

  if (userStore.hasLoginType('IP')) {
    // IP users can't access the account pages
    return navigateTo({
      name: 'frontpage',
    })
  }
})
